.icon {
  transition: transform ease-in 150ms;
  fill: var(--tw-blue-200);
  animation: enlargeShrink 2s ease-in-out infinite alternate;
  transform-origin: center;
}

.icon-1 {
  animation-delay: 0.5s;
}

.icon-2 {
  animation-delay: 1s;
}

.icon-3 {
  animation-delay: 1.5s;
}

@keyframes enlargeShrink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
    fill: var(--gold-one);
  }
}

.icon:hover,
.icon:focus,
.icon:active {
  transform: scale(1.35);
  fill: var(--gold-one);
  animation: none;
}

.link {
  position: relative;
  color: var(--link-color);
}

.link::before,
.link::after {
  position: absolute;
  background-color: var(--link-color);
  content: "";
  width: 0;
  height: 2px;
  transition: width 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.link::before {
  left: 0;
  bottom: 0;
}

.link::after {
  right: 0;
  bottom: 0;
}

.link:hover::before,
.link:hover::after {
  width: 50%;
}

.link-color-1 {
  --link-color: #ff4d4d;
}

.link-color-2 {
  --link-color: #ffa500;
}

.link-color-4 {
  --link-color: #00cc00;
}

.link-js {
  --link-color: #046fda;
}

.link-datadog {
  --link-color: #8a3ffc;
}

.link-mongo {
  --link-color: #57b657;
}

.link-amazon {
  --link-color: #ff9900;
}

.link-zillow {
  --link-color: #0074e4;
}

.link-synchrony {
  --link-color: #ffc72c;
}

.link-gold {
  --link-color: var(--gold-one);
  background: var(--link-color);
  background-clip: text;
  background: -webkit-linear-gradient(to right, var(--gold-one), var(--gold-two), var(--gold-three));
  background: -moz-linear-gradient(to right, var(--gold-one), var(--gold-two), var(--gold-three));
  background: linear-gradient(to right, var(--gold-one), var(--gold-two), var(--gold-three));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.link-purple {
  --link-color: purple;
}

.link-pink {
  --link-color: pink;
}
